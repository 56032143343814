// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app--dFAfr{height:100dvh;width:100dvw;display:flex;flex-direction:column}.main--bCdz5{flex:1}.dev-toolbar--EUH10{font-size:14px;background:orange}.label--SKk4H{display:flex;align-items:center}`, "",{"version":3,"sources":["webpack://./src/app.module.scss"],"names":[],"mappings":"AAAA,YACE,aAAA,CACA,YAAA,CACA,YAAA,CACA,qBAAA,CAGF,aACE,MAAA,CAGF,oBACE,cAAA,CACA,iBAAA,CAGF,cACE,YAAA,CACA,kBAAA","sourcesContent":[".app {\n  height: 100dvh;\n  width: 100dvw;\n  display: flex;\n  flex-direction: column;\n}\n\n.main {\n  flex: 1;\n}\n\n.dev-toolbar {\n  font-size: 14px;\n  background: orange;\n}\n\n.label {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `app--dFAfr`,
	"main": `main--bCdz5`,
	"dev-toolbar": `dev-toolbar--EUH10`,
	"label": `label--SKk4H`
};
export default ___CSS_LOADER_EXPORT___;
