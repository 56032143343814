// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container--RICrb{padding:1em;display:flex;flex-direction:column;gap:1em}.header--Gkk7E{margin:0}.link--kszmx{border:none;outline:none;background-color:#000;color:#fff;padding:.5em;-webkit-text-decoration:none;text-decoration:none}`, "",{"version":3,"sources":["webpack://./src/page/root.module.scss"],"names":[],"mappings":"AAAA,kBACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,eACE,QAAA,CAGF,aACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,YAAA,CACA,4BAAA,CAAA,oBAAA","sourcesContent":[".container {\n  padding: 1em;\n  display: flex;\n  flex-direction: column;\n  gap: 1em;\n}\n\n.header {\n  margin: 0;\n}\n\n.link {\n  border: none;\n  outline: none;\n  background-color: black;\n  color: white;\n  padding: 0.5em;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container--RICrb`,
	"header": `header--Gkk7E`,
	"link": `link--kszmx`
};
export default ___CSS_LOADER_EXPORT___;
