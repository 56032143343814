// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container--pnRMR{position:absolute;bottom:0}.link--p4pcx{display:block;background:#fff;color:#000;-webkit-text-decoration:none;text-decoration:none;padding:.5em}`, "",{"version":3,"sources":["webpack://./../game/src/route/root.module.scss"],"names":[],"mappings":"AAAA,kBACE,iBAAA,CACA,QAAA,CAGF,aACE,aAAA,CACA,eAAA,CACA,UAAA,CACA,4BAAA,CAAA,oBAAA,CACA,YAAA","sourcesContent":[".container {\n  position: absolute;\n  bottom: 0;\n}\n\n.link {\n  display: block;\n  background: white;\n  color: black;\n  text-decoration: none;\n  padding: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container--pnRMR`,
	"link": `link--p4pcx`
};
export default ___CSS_LOADER_EXPORT___;
